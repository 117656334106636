import { useUnreadInlineComments } from '@confluence/unread-comments';

import { CurrentView, useCommentsPanel } from './useCommentsPanel';
import { scrollCommentsPanel } from '../helper/commentsPanelHelper';

const commentsPanelListContainerId = 'comments-panel-list-container';
const commentThreadContainerId = (annotationId: string) =>
	`comment-thread-${annotationId}-container`;

export const useCommentsPanelScroll = () => {
	const [{ currentView }, { setCurrentView }] = useCommentsPanel();
	const [{ unreadCommentsListState }] = useUnreadInlineComments();

	const scrollToAnnotationId = (annotationId: string) => {
		const unreadCommentAnnotationIds =
			unreadCommentsListState.map((unreadComment) => unreadComment.inlineMarkerRef) || [];
		let viewUpdated = false;

		// If the comment is read and the current view is not open, switch to open view
		// If the comment is unread and the current view is not unread and open, still switch to open view
		if (
			(!unreadCommentAnnotationIds.includes(annotationId) && currentView !== CurrentView.OPEN) ||
			(unreadCommentAnnotationIds.includes(annotationId) &&
				currentView !== CurrentView.UNREAD &&
				currentView !== CurrentView.OPEN)
		) {
			setCurrentView(CurrentView.OPEN);
			viewUpdated = true;
		}

		// This is a short term fix to ensure the data is loaded before scrolling.
		// This will be replaced with checking the loading states of each view after the data laoding refactor is complete.
		setTimeout(
			() => {
				scrollCommentsPanel({
					containerId: commentsPanelListContainerId,
					commentMarkerRef: commentThreadContainerId(annotationId),
				});
			},
			viewUpdated ? 1500 : 0,
		);
	};

	return {
		scrollToAnnotationId,
	};
};
