import memoizeOne from 'memoize-one';
import isEqual from 'lodash/isEqual';

import type { InlineCommentState, AnnotationState } from '@atlaskit/editor-plugins/annotation';
// actually an enum not a type
import { AnnotationMarkStates, AnnotationTypes } from '@atlaskit/adf-schema';

import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { getLogger } from '@confluence/logger';
import type {
	AnnotationStateNode,
	AnnotationStateQueryType,
	AnnotationStateQueryVariables,
	InlineCommentLocation,
} from '@confluence/inline-comments-queries';
import { AnnotationStateQuery } from '@confluence/inline-comments-queries';

const logger = getLogger('inline-comments-common:state-resolver');
// TODO: Create a shared editor/renderer function

type RendererAnnotationMap = {
	[key: string]: number;
};

// TODO: Create a shared editor/renderer function
export const mapRendererAnnotationStates = memoizeOne(
	(
		annotations: string[],
		validAnnotationsCallback: (validAnnotations: string[]) => void,
		states?: AnnotationStateNode[],
	) => {
		const rendererAnnotationMap: RendererAnnotationMap = {};
		const annotationState: Array<AnnotationState<AnnotationTypes, AnnotationMarkStates>> = [];

		annotations.forEach((id, idx) => {
			// this will override any repeat ids given by the Renderer
			rendererAnnotationMap[id] = idx;
			annotationState.push({
				annotationType: AnnotationTypes.INLINE_COMMENT,
				id,
				state: AnnotationMarkStates.RESOLVED,
			});
		});

		states?.forEach((state) => {
			const isResolved = Boolean(
				(state.location as InlineCommentLocation)?.inlineResolveProperties?.resolved,
			);

			// mark all unresolved annotations to be displayed by the renderer
			if (!isResolved) {
				const annotationId = (state.location as InlineCommentLocation).inlineMarkerRef;

				if (annotationId) {
					const index = rendererAnnotationMap[annotationId];

					/**
					 * If the BE returns an annotation that the Renderer did not
					 * provide, we could error here. Also account for 0 index.
					 */
					if (index || index === 0) {
						annotationState[index].state = AnnotationMarkStates.ACTIVE;
					}
				}
			}
		});

		const validAnnotations = annotations.filter((annotation) => {
			const index = rendererAnnotationMap[annotation];

			return annotationState[index].state === AnnotationMarkStates.ACTIVE;
		});

		// Record the valid annotations to use for navigation and query params
		validAnnotationsCallback(validAnnotations);

		return annotationState;
	},
	isEqual,
);

export const _mapAnnotationStates = memoizeOne(
	(annotations, states, updateUnresolvedInlineComment) => {
		/**
		 * Build a map of annotation states with a default of true for resolved.
		 * This is to accommodate deleted annotations that still exist in the ADF.
		 */
		const editorAnnotationMap: any = {};
		const annotationState: Array<AnnotationState<AnnotationTypes, InlineCommentState>> = [];

		annotations.forEach((id: string, idx: number) => {
			// this will override any repeat ids given by the Editor
			editorAnnotationMap[id] = idx;
			annotationState.push({
				annotationType: AnnotationTypes.INLINE_COMMENT,
				id,
				state: {
					resolved: true,
				},
			});
		});

		states.forEach((state: any) => {
			const isResolved = Boolean(state?.location?.inlineResolveProperties?.resolved);

			// mark all unresolved annotations to be displayed by the editor
			if (!isResolved) {
				const annotationId = state?.location?.inlineMarkerRef || '';
				const index = editorAnnotationMap[annotationId];

				/**
				 * If the BE returns an annotation that the Editor did not
				 * provide, we could error here. Also account for 0 index.
				 */
				if (index || index === 0) {
					annotationState[index].state.resolved = false;
				}
			}
		});

		//Ordered list of unresolved inline comments
		const unresolvedInlineComments = annotations.filter((annotationId: string) => {
			const index = editorAnnotationMap[annotationId];
			if (!annotationState[index].state.resolved) {
				return annotationId;
			}
		});

		updateUnresolvedInlineComment(unresolvedInlineComments);
		return annotationState;
	},
	isEqual,
);

// TODO: Create a shared editor/renderer function
export const getEditorResolvedState = async ({
	annotations,
	pageId,
	triggerAnalyticsEvent,
	updateUnresolvedInlineComment,
}: {
	annotations: string[];
	pageId: string;
	triggerAnalyticsEvent: any;
	updateUnresolvedInlineComment: any;
}) => {
	if (!annotations.length) {
		return Promise.resolve([]);
	}
	return getApolloClient()
		.query({
			query: AnnotationStateQuery,
			fetchPolicy: 'network-only',
			variables: {
				pageId,
				contentStatus: ['DRAFT', 'CURRENT'],
			},
		})
		.then((data) => {
			return _mapAnnotationStates(
				annotations,
				data.data.comments.nodes,
				updateUnresolvedInlineComment,
			);
		})
		.catch((error) => {
			// COMMENTS-211 - When this code executes sometimes the `triggerAnalyticsEvent` is passed in as a promise
			//                this causes this call to throw an error where `.fire()` is not defined and thus the
			//                code below it which handles the various expected errors thrown to not run and thus
			//                the errors are thrown up the stack as unhandled ui errors causing a lot of noise
			try {
				if (triggerAnalyticsEvent) {
					triggerAnalyticsEvent({
						data: {
							source: 'editorComponent',
							action: 'failed',
							actionSubject: 'annotationState',
							actionSubjectId: 'inlineComments',
							attributes: {
								errorName: error.name,
								errorMessage: error.message,
							},
						},
						type: 'sendOperationalEvent',
					}).fire();
				}
			} catch (e) {
				logger.error`Error when logging analytics event, ${e}`;
			}

			if (
				isUnauthorizedError(error) ||
				// CEMS-1343 - Some specific pages have annotations that have a "null" id because we
				//             still informed the editor on failed creation attempts
				error?.message.includes('java.lang.NullPointerException') ||
				// COMMENTS-211 - If we don't find any inline comments for the given markerRefList
				//               we should mark it handled and return an empty list to be consumed
				error?.message.includes('confluence.api.service.exceptions.NotFoundException')
			) {
				markErrorAsHandled(error);
				return [];
			} else {
				logger.error`Error when retrieving resolved status for marks, ${error}`;
				throw error;
			}
		});
};

let rendererAnnotationsIds: string[];

export const getRendererAnnotationStates = async ({
	annotations,
	pageId,
	triggerAnalyticsEvent,
	validAnnotationsCallback,
	isNestedRender,
}: {
	annotations: string[];
	pageId: string;
	triggerAnalyticsEvent: any;
	validAnnotationsCallback: (validAnnotations: string[]) => void;
	isNestedRender: boolean;
}): Promise<AnnotationState<AnnotationTypes, AnnotationMarkStates>[]> => {
	// We need to have the page level renderer annotations
	// When the page renders the value of isNestedRender is false as per PageContentRendererFabrics
	// Later, when the macro renders isNestedRender is true
	// so, we will hold the value of annotations for the page renderer when isNestedRender is false

	if (!annotations.length) {
		return Promise.resolve([]);
	}

	if (!isNestedRender) {
		rendererAnnotationsIds = annotations;
	}

	return getApolloClient()
		.query<AnnotationStateQueryType, AnnotationStateQueryVariables>({
			query: AnnotationStateQuery,
			fetchPolicy: isNestedRender ? 'cache-first' : 'network-only',
			variables: {
				pageId,
			},
		})
		.then((data) => {
			// Filter null elements
			const annotationStateNodes = data.data.comments?.nodes?.filter((node) => node !== null) as
				| AnnotationStateNode[]
				| undefined;

			return mapRendererAnnotationStates(
				rendererAnnotationsIds,
				validAnnotationsCallback,
				annotationStateNodes,
			);
		})
		.catch((error) => {
			// COMMENTS-1131 - When this code executes sometimes the `triggerAnalyticsEvent` is passed in as a promise
			//                this causes this call to throw an error where `.fire()` is not defined and thus the
			//                code below it which handles the various expected errors thrown to not run and thus
			//                the errors are thrown up the stack as unhandled ui errors causing a lot of noise
			try {
				if (triggerAnalyticsEvent) {
					triggerAnalyticsEvent({
						source: 'rendererComponent',
						action: 'failed',
						actionSubject: 'annotationState',
						actionSubjectId: 'inlineComments',
						attributes: {
							errorName: error.name,
							errorMessage: error.message,
						},
					}).fire();
				}
			} catch (e) {
				logger.error`Error when logging analytics event, ${e}`;
			}

			if (
				isUnauthorizedError(error) ||
				// CEMS-1343 - Some specific pages have annotations that have a "null" id because we
				//             still informed the editor on failed creation attempts
				error?.message.includes('java.lang.NullPointerException') ||
				// COMMENTS-211 - If we don't find any inline comments for the given markerRefList
				//               we should mark it handled and return an empty list to be consumed
				error?.message.includes('confluence.api.service.exceptions.NotFoundException')
			) {
				markErrorAsHandled(error);
				return [];
			} else {
				logger.error`Error when retrieving resolved status for marks, ${error}`;
				throw error;
			}
		});
};
