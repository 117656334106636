import React, { useEffect, useMemo } from 'react';
import type { FC } from 'react';

import { Inline, xcss } from '@atlaskit/primitives';

import { CommentNavigation as CommentNavigationCommon } from '@confluence/inline-comments-common';
import type { Comment } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { fg } from '@confluence/feature-gating';

import { useInlineCommentNavigation } from './hooks/useInlineCommentNavigation';

type CommentNavigationProps = {
	pageId: string;
	commentData: Comment;
	isEditor?: boolean;
	onNavigationClick: (nextMarkerRef: string) => void;
};

const positionRightStyles = xcss({
	position: 'absolute',
	top: 'space.150',
	right: 'space.800',
});

const positionLeftStyles = xcss({
	position: 'absolute',
	top: 'space.150',
	left: 'space.150',
});

export const CommentNavigation: FC<CommentNavigationProps> = ({
	pageId,
	commentData,
	isEditor,
	onNavigationClick,
}) => {
	const scrollableContainerSelector = isEditor
		? '.fabric-editor-popup-scroll-parent'
		: '#content-body';
	const {
		currentCommentIndex,
		goToNextInlineComment,
		goToPrevInlineComment,
		setCurrentlyNavigatedComment,
		commentsCount,
	} = useInlineCommentNavigation({
		isEditor,
		scrollableContainerSelector,
		pageId,
		onNavigationClick,
	});

	useEffect(() => {
		setCurrentlyNavigatedComment(commentData);
	}, [commentData, setCurrentlyNavigatedComment]);

	return useMemo(
		() => (
			<Inline
				xcss={fg('confluence-frontend-comments-panel') ? positionRightStyles : positionLeftStyles}
			>
				<CommentNavigationCommon
					goToNextInlineComment={goToNextInlineComment}
					goToPrevInlineComment={goToPrevInlineComment}
					currentCommentIndex={currentCommentIndex}
					commentsCount={commentsCount}
					disableCommentCount={fg('confluence-frontend-comments-panel')}
				/>
			</Inline>
		),
		[commentsCount, currentCommentIndex, goToNextInlineComment, goToPrevInlineComment],
	);
};
