import React, { useCallback, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl-next';
import Mousetrap from 'mousetrap';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { useIntl } from 'react-intl';

import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import Button from '@atlaskit/button/new';
import CommentIcon from '@atlaskit/icon/core/comment';
import { Text, xcss, Inline } from '@atlaskit/primitives';
import { N90 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import {
	calculateElementOffset,
	getSidebarId,
	scrollToElementInEditor,
	scrollTargetIntoViewIfNeeded,
} from '@confluence/comments-util';
import {
	ReactionsProvider,
	useCommentContentContext,
	useCommentContentDispatchContext,
} from '@confluence/comment-context';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import {
	useCommentsPanel,
	useShowCommentsPanel,
	useCommentsPanelScroll,
} from '@confluence/comments-panel';
import { DialogsStateContainer } from '@confluence/dialogs';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import {
	SidebarContainer,
	CloseButtonContainer,
	CloseButton,
	TopContainer,
} from '@confluence/inline-comments-common/entry-points/styled';
import type { Comment } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import {
	INLINE_COMMENTS_SHORTCUT,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR,
	ShortcutVisualizer,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER,
} from '@confluence/shortcuts';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';

import { i18n, STICKY_COMMENT_VERTICAL_OFFSET } from './inlineCommentConstants';
import { CommentNavigation } from './CommentNavigation';

export type CommentNavigationOptions = {
	commentData: Comment;
	showNavigation: boolean;
	onNavigationClick: (nextMarkerRef: string) => void;
};

type CommentSidebarProps = {
	pageId: string;
	onClose: ((hasContentChanged?: boolean) => void) | undefined;
	annotationElement?: HTMLElement | null;
	navigationOptions?: CommentNavigationOptions;
	isEditor?: boolean;
	isViewCommentMode?: boolean;
	children: ReactNode;
	sidebarOffset: number;
	commentId?: string;

	/**
	 * Should scroll comment sidebar into view after mounting?
	 * When creating comment: scroll the entire sidebar into view;
	 * When viewing comment: scroll the first comment into view only to cater for long comment threads.
	 */
	scrollIntoView?: boolean;
	isOpeningMediaCommentFromToolbar?: boolean;
	sidebarEl: React.MutableRefObject<HTMLElement | null>;
	annotationId?: string;
	inlineCommentRef?: (node: any) => void;
	nudgeRef?: React.MutableRefObject<HTMLElement | null>;
};

const viewAllContainerStyles = xcss({
	position: 'absolute',
	top: 'space.150',
	left: 'space.075',
	alignItems: 'center',
	justifyContent: 'center',
});

export const handleIntersection = (
	entry: IntersectionObserverEntry,
	setIsSticky: (_isSticky: boolean) => void,
	setSidebarOffset: (_offset: number) => void,
	sidebarOffset: number,
) => {
	if (entry.intersectionRatio === 0) {
		setIsSticky(false);
		setSidebarOffset(sidebarOffset);
	} else {
		setIsSticky(true);
		setSidebarOffset(STICKY_COMMENT_VERTICAL_OFFSET);
	}
};

export const CommentSidebar: FC<CommentSidebarProps> = ({
	pageId,
	onClose,
	annotationElement,
	navigationOptions,
	isEditor,
	isViewCommentMode,
	children,
	sidebarOffset,
	commentId,
	scrollIntoView,
	isOpeningMediaCommentFromToolbar,
	sidebarEl,
	annotationId,
	inlineCommentRef = () => {},
	nudgeRef,
}) => {
	const [isRefSet, setRef] = useState<boolean>(false);
	const [isSticky, setIsSticky] = useState<boolean>(false);
	const [newSidebarOffset, setSidebarOffset] = useState<number>(sidebarOffset);

	const { hasContentChanged } = useCommentContentContext();
	const { resetContentChanged } = useCommentContentDispatchContext();
	const [{ isViewMode: isLiveViewMode }] = useLivePageMode();
	const { showCommentsPanel } = useShowCommentsPanel();

	const [, { setCurrentlySelectedCommentMarkerRef, setIsCommentsPanelOpen }] = useCommentsPanel();
	const { scrollToAnnotationId } = useCommentsPanelScroll();

	useEffect(() => {
		let offset = 0;

		// To refactor: we should be able to use useCommentSidebarOffset hook higher up the component tree
		if (annotationElement && !isEditor) {
			let scrollParent: HTMLElement | null;

			if (isEditor) {
				scrollParent = document.querySelector('.fabric-editor-popup-scroll-parent');
				offset = calculateElementOffset(annotationElement, scrollParent);
			} else {
				/*
        There's two cases here: a view comment and a create comment.
        We need the correct scrollParent for each case.
        Since it's not the editor in this logical branch we should use #content as the scrollParent.
        */
				scrollParent = document.querySelector('#content');

				if (scrollParent) {
					let annotationOffset = 0;

					if (isViewCommentMode) {
						// If the sidebar is rendering a view comment, we can use the annotationElement directly.
						annotationOffset = calculateElementOffset(annotationElement);
					} else {
						/*
            If the sidebar is rendering a create comment the annotationElement is actually the wrapperDOM
            we need to find the actual highlight instead. We can find this by looking for the mark because
            there will always be a mark before the create comment button is clicked.
            */
						const actualHiglightElement: HTMLElement | null = scrollParent.querySelector(
							`[data-annotation-draft-mark="true"]`,
						);
						annotationOffset = calculateElementOffset(actualHiglightElement, undefined, true);
					}
					offset = annotationOffset + scrollParent.offsetTop;
				}
			}
			setSidebarOffset(offset);
		}
	}, [annotationElement, isEditor, isViewCommentMode]);

	useEffect(() => {
		// Scroll comments sidebar into view in editor
		// When creating comment: always scroll into view if needed (see CreateComment)
		// When viewing comment: only scroll into view if there's no focused comment or unread comment (see InlineComment)
		if (isRefSet && sidebarEl.current && isEditor) {
			if (fg('confluence_frontend_media_scroll_fix')) {
				if (isOpeningMediaCommentFromToolbar && annotationElement) {
					scrollToElementInEditor({
						targetElement: annotationElement,
						viewingRoomOffset: -150,
					});
				}
			} else {
				if (scrollIntoView) {
					const scrollParent = document.querySelector('.fabric-editor-popup-scroll-parent');

					const target = isViewCommentMode
						? // If viewing the comments, scroll the first comment into view only to cater for long comment threads
							sidebarEl.current.querySelector(`[data-comment-id="${commentId}"]`)
						: sidebarEl.current;

					if (scrollParent && target) {
						scrollTargetIntoViewIfNeeded(target, scrollParent);
					}
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isRefSet,
		scrollIntoView,
		annotationElement,
		isOpeningMediaCommentFromToolbar,
		commentId,
		isViewCommentMode,
		isEditor,
	]);

	useEffect(() => {
		let mouseTrap: any;
		if (isRefSet && sidebarEl.current) {
			mouseTrap = new Mousetrap(sidebarEl.current);
			// prevent saving via mod+enter from also publishing the page in edit mode
			mouseTrap.bind(INLINE_COMMENTS_SHORTCUT, (event: Event) => {
				event.stopPropagation();
			});
		}

		return () => {
			if (mouseTrap) {
				mouseTrap.reset();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRefSet]);

	/**
	 * useEffect to handle sticky comment behavior when attached to a sticky table header.
	 * As the user scrolls, the IntersectionObserver triggers the callback the moment the table header starts/stops being sticky
	 */
	useEffect(() => {
		const stickyHeader = annotationElement?.closest('.pm-table-sticky-wrapper');

		const sidebarBoundingRect = sidebarEl.current?.getBoundingClientRect();
		const isSidebarExtendedBeyondViewport = (sidebarBoundingRect?.bottom ?? 0) > window.innerHeight;

		if (
			stickyHeader &&
			!isSidebarExtendedBeyondViewport &&
			fg('sticky_comments_on_table_headers')
		) {
			// eslint-disable-next-line no-restricted-syntax
			const observer = new IntersectionObserver(
				([entry]) => handleIntersection(entry, setIsSticky, setSidebarOffset, sidebarOffset),
				{ threshold: 1.0 },
			);
			observer.observe(stickyHeader);

			return () => observer.disconnect();
		}
	}, [annotationElement, sidebarOffset, sidebarEl]);

	const handlePropagation = (event: any) => {
		/**
		 * prevents an event bubbling up to removing focus
		 * from the highlight displaying the sidebar
		 */
		event.stopPropagation();
	};

	const handleOnClose = useCallback(
		(dialogs: DialogsStateContainer) => {
			if (hasContentChanged) {
				dialogs.showModal(CommentWarningDialog, {
					onConfirm: () => {
						resetContentChanged();
						onClose && onClose(false);
					},
				});
			} else {
				onClose && onClose(hasContentChanged);
			}
		},
		[hasContentChanged, onClose, resetContentChanged],
	);

	const handleViewAllClick = useCallback(
		(dialogs: DialogsStateContainer) => {
			// TODO: Update this in follow up
			showCommentsPanel();
			handleOnClose(dialogs);
			setIsCommentsPanelOpen(true);
			if (annotationId) {
				setCurrentlySelectedCommentMarkerRef(annotationId);
				scrollToAnnotationId(annotationId);
			}
		},
		[
			handleOnClose,
			setIsCommentsPanelOpen,
			setCurrentlySelectedCommentMarkerRef,
			annotationId,
			showCommentsPanel,
			scrollToAnnotationId,
		],
	);

	const sidebarId = getSidebarId(isEditor, isViewCommentMode);

	const mode = isEditor ? 'edit' : 'view';

	const intl = useIntl();
	const closeCommentBox = intl.formatMessage(i18n.closeCommentBox);

	return (
		<ErrorBoundary attribution={Attribution.COLLABORATION}>
			<Subscribe to={[DialogsStateContainer]}>
				{(dialogs: DialogsStateContainer) => (
					<ReactionsProvider contentId={pageId}>
						<SidebarContainer
							offset={newSidebarOffset}
							isSticky={isSticky}
							onClick={handlePropagation}
							id={sidebarId}
							data-testid={sidebarId}
							data-cy={sidebarId}
							mode={mode}
							ref={(el) => {
								sidebarEl.current = el;
								if (nudgeRef) nudgeRef.current = el;
								setRef(true);
								inlineCommentRef(el);
							}}
						>
							<TopContainer showNavigation={navigationOptions && navigationOptions.showNavigation}>
								{annotationId && fg('confluence-frontend-comments-panel') && (
									<Inline xcss={viewAllContainerStyles} testId="view-all-container">
										<Button
											onClick={() => handleViewAllClick(dialogs)}
											iconBefore={(iconProps) => (
												<CommentIcon
													{...iconProps}
													label="Comment Icon"
													color="var(--ds-icon-subtle)"
												/>
											)}
											appearance="subtle"
											spacing="compact"
											testId="comment-sidebar-viewall-button"
										>
											<Text color="color.text.subtle">
												{intl.formatMessage(i18n.viewAllCommentsButtonText)}
											</Text>
										</Button>
									</Inline>
								)}
								{navigationOptions && navigationOptions.showNavigation && (
									<CommentNavigation
										pageId={pageId}
										commentData={navigationOptions.commentData}
										isEditor={isEditor}
										onNavigationClick={navigationOptions.onNavigationClick}
									/>
								)}
								<Tooltip
									content={
										<ShortcutVisualizer
											shortcut={
												isEditor &&
												!(isLiveViewMode && fg('confluence_frontend_live_edit_keyboard_shortcut'))
													? TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR
													: TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER
											}
											isEditorShortcut={
												isEditor &&
												!(isLiveViewMode && fg('confluence_frontend_live_edit_keyboard_shortcut'))
											}
											contentBefore={<FormattedMessage {...i18n.closeSidebarTooltip} />}
										/>
									}
									hideTooltipOnClick
									position="top"
								>
									<CloseButtonContainer>
										<CloseButton
											appearance="subtle"
											onClick={() => handleOnClose(dialogs)}
											onMouseDown={(e) => e.preventDefault()}
											testId="close-comment-sidebar-button"
											data-cy={`${isEditor ? 'editor' : 'renderer'}-close-${
												isViewCommentMode ? 'view' : 'create'
											}-comment`}
											iconBefore={
												<CrossIcon
													label={closeCommentBox}
													color={token('color.icon.subtle', N90)}
													spacing="none"
												/>
											}
										/>
									</CloseButtonContainer>
								</Tooltip>
							</TopContainer>
							{children}
						</SidebarContainer>
					</ReactionsProvider>
				)}
			</Subscribe>
		</ErrorBoundary>
	);
};
